<template>
  <v-container>
    <h1>Dashboard</h1>
    <p>Und wer möchte, kann auch mal eine
      <router-link to="/disclosure/new">neue Anfrage</router-link>
      in Sachen Selbstauskunft eingeben.
    </p>
    <v-row>
      <v-col cols="4">
        <v-card class="mx-auto">
          <v-sheet
              class="v-sheet--offset mx-auto"
              color="accent"
              elevation="4"
              max-width="calc(100% - 32px)"
          >
            <v-sparkline
                :labels="labels"
                :value="value"
                color="white"
                line-width="2"
                padding="16"
            ></v-sparkline>
          </v-sheet>

          <v-card-text class="pt-8">
            <div class="title font-weight-light mb-2">
              Anfragen Selbstauskunft (fake)
            </div>
            <div class="subheading font-weight-light grey--text">
              Der letzen 24 Stunden
            </div>
            <v-divider class="my-2"></v-divider>
            <v-icon
                class="mr-2"
                small
            >
              mdi-clock
            </v-icon>
            <span class="caption grey--text font-weight-light">letze Anfrage vor 26 Minuten</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    labels: [
      '00:00',
      '03:00',
      '06:00',
      '09:00',
      '12:00',
      '15:00',
      '18:00',
      '21:00'
    ],
    value: [
      200,
      675,
      410,
      390,
      310,
      460,
      250,
      240
    ]
  })
}
</script>

<style>
.v-sheet--offset {
  top: 1em;
  position: relative;
}
</style>
